import React from "react"

import Layout from "../components/web/layout"
import SEO from "../components/web/seo"
import ArticleList from "../components/web/article_list"

const SecondPage = () => (
  <Layout>
    <SEO title="Articles" />
    <ArticleList></ArticleList>
  </Layout>
)

export default SecondPage
