import React, { useState } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./article_list.css"
import { GetArticles } from "../../hooks/get_articles.js"


const DEFAULT_LETS_TALK_INDEX = 0;


function getCard(node, hero, handleEnter, handleLeave, key) {
  return (
    <Link className={hero ? "herolink" : "article_list_link"}
          to={node.frontmatter.path} key={node.frontmatter.title}
          onMouseEnter={() => handleEnter(node)} onMouseLeave={handleLeave}
          key={key}>
      <Img className="thumbnail"
            fixed={node.frontmatter.image.childImageSharp.fixed}
            alt={node.frontmatter.title}/>
      <h2 className={hero ? "heroposttitle" : "posttitle"}>
        {node.frontmatter.title}
      </h2>
      <p className={hero ? "herotagline" : "tagline"}>
        {node.frontmatter.tagline}
      </p>
    </Link>
  )
}

export default (props) => {

  const is_small = (props.header_size === 'small');
  const articles = GetArticles().allMarkdownRemark.edges;
  const article_num = articles.length;
  const [letsTalkIndex, setLetsTalkIndex] = useState(DEFAULT_LETS_TALK_INDEX);
  const handleEnter = (i) => setLetsTalkIndex(i);
  const handleLeave = () => setLetsTalkIndex(DEFAULT_LETS_TALK_INDEX);
  return (
    <>
    <h1 className={ is_small ? 'letstalkintro_small' : 'letstalkintro' }>
      {`Let's Talk`}
    </h1>
    <div className={ is_small ? 'letstalktopicholder_small' : 'letstalktopicholder' }>
      {articles.map(({ node }, i) => (
        <h1 className={ is_small ? 'letstalktopic_small' : 'letstalktopic' }
          style={letsTalkIndex == i + 1 ? {}: {opacity: 0}}
          key={i + 1}>
          {node.frontmatter.letstalk}
        </h1>
      ))}
    </div>
    <div className="postlist">
      {getCard(articles[0].node, true, () => handleEnter(1), handleLeave, 1)}
      <div className="scrollablepostlist">
        {articles.slice(1).map((n, i) => 
          getCard(n.node, false, () => handleEnter(i + 2), handleLeave, i + 2))}
      </div>
    </div>
    </>
  );
}

