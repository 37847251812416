import { useStaticQuery, graphql } from "gatsby"

export const GetArticles = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        sort: { 
          order: DESC, fields: [frontmatter___priority] },
        filter: {
          frontmatter: {
            priority: {gt: 0}
          }
        }
      ) {
        edges {
          node {
            frontmatter {
              path
              date(formatString: "MMMM Do YYYY")
              title
              tagline
              letstalk
              priority
              image {
                childImageSharp {
                  fixed(width: 125, height: 125, quality: 90) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return data;
}